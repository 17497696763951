import Cookies from 'js-cookie'
import { SupplyQuery, SupplyResponse } from 'src/@core/supply/supply.gateway'
import { Supply } from 'src/@core/supply/supply.model'
import { captureException } from '@sentry/nextjs'

const BASE_URL = process.env.NEXT_PUBLIC_TRACKING_URL || 'https://t.qdra.io/api/v1'

export enum EventTypes {
    VIEW_CONTENT = 'ViewContent',
    SCHEDULE = 'Schedule',
    ADD_TO_CART = 'AddToCart',
    INITIATE_CHECKOUT = 'InitiateCheckout',
    SEARCH = 'Search',
}

type EventPayload = {
    event_name: EventTypes
    custom_data: {
        [key: string]: string | Array<string> | number
    }
}

type UserPayload = {
    email: string
    phone: string
    first_name: string
    last_name: string
}

export async function hit(event: EventPayload) {
    const sqclid = await generateSqclid()
    try {
        await fetch(`${BASE_URL}/events`, {
            method: 'POST',
            body: JSON.stringify({
                sqclid: sqclid,
                data: [event],
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
    } catch (e) {
        captureException(e)
    }
}

export async function identify(user: UserPayload) {
    if (process.env.NODE_ENV !== 'production') {
        return
    }

    const sqclid = await generateSqclid()
    const fbclid = Cookies.get('_fbclid')
    try {
        await fetch(`${BASE_URL}/identify`, {
            method: 'POST',
            body: JSON.stringify({
                sqclid: sqclid,
                userData: {
                    ...user,
                    fbclid: fbclid,
                },
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
    } catch (e) {
        captureException(e)
    }
}

export function getSqclid() {
    return Cookies.get('_sqclid')
}

export async function generateSqclid() {
    const existingSqclid = Cookies.get('_sqclid')
    if (existingSqclid) {
        return existingSqclid
    }
    try {
        const res = await fetch(`${BASE_URL}/sqclid`)
        const data = await res.json()
        Cookies.set('_sqclid', data.sqclid)
        return data.sqclid
    } catch (e) {
        captureException(e)
    }
}

export function extractFirstName(name: string) {
    const names = name.split(' ')
    if (names.length > 0) {
        return names[0]
    }
    return name
}

export function extractLastName(name: string) {
    const names = name.split(' ')
    if (names.length > 1) {
        return names.slice(1).join(' ')
    }
    return name
}

export function normalizePrice(supply: Supply): number {
    return Number.parseInt(supply.details['preço'].value) / 100
}

export function searchToEvent(search: SupplyQuery, results: SupplyResponse): EventPayload {
    const top5 = results.data?.length > 0 ? results.data.slice(0, 5) : []
    return {
        event_name: EventTypes.SEARCH,
        custom_data: {
            content_type: 'home_listing',
            search_string: searchStringToEvent(search),
            content_ids: top5.map((s) => s.slug),
            city: 'saopaulo',
            region: 'saopaulo',
            country: 'br',
            currency: 'BRL',
        },
    }
}

export function searchStringToEvent(search: SupplyQuery): string {
    var search_string = ''
    if (search.address) {
        search_string = search.address
    }

    if (search.regions && search.regions.length > 0) {
        search_string = search.regions[0].bairro
    }

    if (search.filters && search.filters.length > 0) {
        if (search_string != '') {
            search_string += ' - '
        }

        search_string += search.filters
            .map((f) => {
                return `${f.name}:${f.value}`
            })
            .join(' | ')
    }

    if (search.sort) {
        if (search_string != '') {
            search_string += ' - '
        }

        search_string += `${search.sort.name}:${search.sort.sort}`
    }

    return search_string
}
