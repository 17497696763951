import { AuthAction, AuthActionKind, AuthContextState } from './auth.model'

export function authReducer(state: AuthContextState, action: AuthAction): AuthContextState {
    switch (action.type) {
        case AuthActionKind.SET_UNIDENTIFIED:
            return {
                ...state,
                loading: false,
                user: undefined,
                auth: undefined,
            }
        case AuthActionKind.SET_AUTH: {
            return {
                ...state,
                auth: action.payload.auth,
            }
        }
        case AuthActionKind.SET_USER: {
            return {
                ...state,
                user: action.payload.user,
                loading: false,
            }
        }
        case AuthActionKind.LOADING:
            return {
                ...state,
                loading: true,
            }
    }

    return { ...state }
}
