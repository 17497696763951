import { User } from 'src/@core/user/user.model'

export type AuthState = {
    access_token: string
    refresh_token: string
}

export type AuthContextState = {
    auth?: AuthState
    user?: User
    loading: boolean
}

export enum AuthActionKind {
    LOADING = 'LOADING',
    SET_USER = 'SET_USER',
    SET_AUTH = 'SET_AUTH',
    SET_UNIDENTIFIED = 'SET_UNIDENTIFIED',
}

export type AuthAction =
    | {
          type: AuthActionKind.LOADING
      }
    | {
          type: AuthActionKind.SET_USER
          payload: {
              user: User
          }
      }
    | {
          type: AuthActionKind.SET_AUTH
          payload: {
              auth: AuthState
          }
      }
    | {
          type: AuthActionKind.SET_UNIDENTIFIED
      }
