'use client'

import React, {
    createContext,
    PropsWithChildren,
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useState,
} from 'react'
import {
    fetchFavorites,
    linkFavorite,
    unlinkFavorite,
} from 'src/@core/supply/favorites/favorites.gateway'
import { useRouter } from 'next/navigation'
import { AuthActionKind, AuthContextState, AuthState } from 'src/@core/auth/auth.model'
import { cleanAuth, getAuthPreview, storeAuth } from 'src/@core/auth/auth.usecase'
import profileUseCase from 'src/@core/user/user.usecase'
import { User } from 'src/@core/user/user.model'
import { authReducer } from '../@core/auth/auth.entity'

type AuthContextProps = {
    auth: AuthContextState
    showLoginModal: boolean
    favorites: { [supplyId: string]: boolean }
    loginStep: number
    isLoggedIn: boolean
    toggleLoginModal: () => void
    setUser: (user: User) => void
    setAuth: (auth: AuthState) => void
    onLoginSuccess: (auth: AuthState) => Promise<void>
    toggleFavorite: (supplyId: string, value: boolean) => void
    logout(): void
    setLoginStep: (step: number) => void
}

const initialState: AuthContextState = {
    loading: true,
}

export const AuthContext = createContext<AuthContextProps>({
    auth: initialState,
    loginStep: 1,
    isLoggedIn: false,
    showLoginModal: false,
    favorites: {},
    onLoginSuccess: async () => {},
    toggleLoginModal: () => {},
    setUser: () => {},
    setAuth: () => {},
    toggleFavorite: () => {},
    logout: () => {},
    setLoginStep: () => {},
})

export function AuthProvider({ children }: PropsWithChildren<{}>) {
    const [auth, dispatch] = useReducer(authReducer, initialState)
    const [favorites, setFavorites] = useState<{ [supplyId: string]: boolean }>({})

    const [showModal, setShowModal] = useState(false)
    const [loginStep, setLoginStep] = useState(1)
    const router = useRouter()

    const logout = useCallback(() => {
        cleanAuth()
        dispatch({ type: AuthActionKind.SET_UNIDENTIFIED })
        router.refresh()
    }, [])

    function updateAuthState(auth: AuthState) {
        if (!auth?.access_token) {
            dispatch({ type: AuthActionKind.SET_UNIDENTIFIED })
            throw new Error('Invalid auth')
        }

        dispatch({ type: AuthActionKind.SET_AUTH, payload: { auth } })
        storeAuth(auth)
    }

    // Load auth
    useEffect(() => {
        getAuthPreview()
            .then(updateAuthState)
            .catch(() => dispatch({ type: AuthActionKind.SET_UNIDENTIFIED }))
    }, [])

    // Reactive when auth happens (reacts state concurrency)
    useEffect(() => {
        if (auth.auth?.access_token) {
            loadProfile()
            loadFavorites()
        }
    }, [auth.auth?.access_token])

    const loadFavorites = useCallback(() => {
        fetchFavorites()
            .then((res) => res.data)
            .catch(() => ({}))
            .then(setFavorites)
    }, [])

    const loadProfile = useCallback(() => {
        dispatch({ type: AuthActionKind.LOADING })
        return profileUseCase
            .loadProfile()
            .then((user) =>
                dispatch({
                    type: AuthActionKind.SET_USER,
                    payload: { user },
                })
            )
            .catch(() =>
                dispatch({
                    type: AuthActionKind.SET_UNIDENTIFIED,
                })
            )
    }, [])

    const toggleFavorite = useCallback(
        async (supplyId: string, value: boolean) => {
            if (value) {
                setFavorites((prev) => ({ ...prev, [supplyId]: true }))
                await linkFavorite(supplyId).catch(() => setShowModal(true))
            } else {
                setFavorites((prev) => {
                    delete prev[supplyId]
                    return prev
                })
                await unlinkFavorite(supplyId).catch(() => setShowModal(true))
            }
        },
        [favorites, auth]
    )

    const setUser = (user: User) => dispatch({ type: AuthActionKind.SET_USER, payload: { user } })

    const onLoginSuccess = useCallback(async (auth: AuthState) => {
        updateAuthState(auth)
        await getAuthPreview()
        setShowModal(false)
    }, [])

    const isLoggedIn = useMemo(() => !!auth?.auth?.access_token, [auth])

    return (
        <AuthContext.Provider
            value={{
                auth,
                favorites,
                loginStep,
                isLoggedIn,
                showLoginModal: showModal,
                setUser,
                setAuth: updateAuthState,
                onLoginSuccess,
                setLoginStep,
                logout,
                toggleFavorite,
                toggleLoginModal: () => setShowModal((v) => !v),
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
