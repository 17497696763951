export type User = {
    name: string
    email: string
    phone: string
    profile_completed: boolean
    business_type: string
    business_type_outro: string
    deal_deadline: string
    optIn: boolean
    coupon?: {
        code: string
        discount?: number
    }
}

export const BUSINESS_TYPE_OUTRO_VALUE = 'Outros'
export const ProfileBusinessTypes = [
    'Academia',
    'Açougue',
    'Armarinho',
    'Autopeças',
    'Bar',
    'Barbearia',
    'Bomboniere',
    'Buffet',
    'Café',
    'Casa de Bolo',
    'Construtora',
    'Consultório de saúde',
    'Crossfit',
    'Dark Kitchen',
    'Distribuidora de Bebidas',
    'Educação',
    'Escola de Idiomas',
    'Estacionamento',
    'Farmácia',
    'Floricultura',
    'Gráfica',
    'Hamburgueria',
    'Hortifruti',
    'Lanchonete',
    'Lava rápido',
    'Lavanderia',
    'Loja de Bijuterias',
    'Loja de Carros',
    'Loja de materiais de construção',
    'Loja de Sapato',
    'Manutenção de Computadores',
    'Mercearia',
    'Ótica',
    'Padaria',
    'Papelaria',
    'Pastelaria',
    'Petshop / Hotel de Pet',
    'Pizzaria',
    'Restaurante',
    'Salão',
    'Sex shop',
    'Sorveteria',
    'Supermercado',
    'Vestuário',
    'Veterinário',
    'Outros',
]

export const ProfileDealDeadlines = [
    'O quanto antes, tenho urgência para alugar',
    'Posso esperar um pouco, não tenho um prazo definido',
    'Ainda estou explorando opções e não tenho pressa',
]
