import {
    setUserId as amplitudeSetUserId,
    Identify as AmplitudeIdentify,
    identify as amplitudeIdentify,
    track as amplitudeTrack,
} from '@amplitude/analytics-browser'
import { Supply, SupplyAmplitude } from 'src/@core/supply/supply.model'
import { supplyToSupplyAmplitude } from '@/tracks/supplyToSupplyAmplitude'
import gtm from './gtm'

interface BaseTrackProps {
    pageType: string
    timestamp: number
    url: string
}

interface SentTrackProps extends BaseTrackProps {
    listing_id: string
    date_solicited?: string
    listing_title: string
    listing_rent_price: number
    total_price: number
    supply?: Supply
}

export const getBaseTracksValues = (): BaseTrackProps => {
    const timestamp = Date.now()
    let url: string = ''
    let pageType: string
    if (typeof window === 'undefined' || window.location.pathname === '/') {
        pageType = 'mainpage'
    } else {
        const path = window.location.pathname
        // TODO: Investigar essa regex
        const regex = /\/{1}[a-z-]+(\/{1}|$)/m
        const pageIdentifier = regex.exec(path)

        switch (pageIdentifier && pageIdentifier[0]) {
            case '/imoveis/':
                pageType = 'propertyList'
                break
            case '/profiling':
                pageType = 'profiling'
                break
            case '/imovel/':
                pageType = 'property'
                break
            case '/termos':
                pageType = 'use_terms'
                break
            case '/politica-de-privacidade':
                pageType = 'privacy_policy'
                break
            case '/select/':
                pageType = 'select'
                break
            case '/calculadora-aluguel':
                pageType = 'calc_rent'
                break
            case '/sua-conta/':
                path.includes('favorit')
                    ? (pageType = 'logged_area-favorites')
                    : path.includes('visit')
                      ? (pageType = 'logged_area-visits')
                      : (pageType = 'logged_area')
                break
            case '/para-voce':
                pageType = 'recomendados'
                break
            case '/proposta':
                pageType = 'proposta'
            default:
                pageType = 'error_unknown_page'
                break
        }
    }

    typeof window === 'undefined' ? null : (url = window.location.pathname)
    return {
        pageType: pageType,
        timestamp: timestamp,
        url: url,
    }
}

export interface buttonTrackProps extends BaseTrackProps {
    buttonType: string
    displayName: string
}
export interface cardTrackProps extends BaseTrackProps {
    cardType: string
    displayName: string
}
export interface filterInterationTrackProps extends BaseTrackProps {
    filterType: string
    displayName: string
}
export interface inputTrackProps extends BaseTrackProps {
    inputType: string
    displayName: string
}
export interface LeadProps {
    name: string
    phone: string
    email: string
    business_type: string
    business_type_outro: string
    deal_deadline: string
    referral_source: string
}
export interface pageviewProps extends BaseTrackProps {
    suppliesQuantity?: number
    supply?: SupplyAmplitude
}
export interface ScheduleSentProps extends LeadProps {
    scheduleDate: string
}

export interface searchProps extends BaseTrackProps {
    displayName: string
}

const trackHubSpot = (eventType: EventType, eventTypeProps: object) => {
    try {
        const _hsq = (window._hsq = window._hsq || [])
        return _hsq.push(
            eventType === EventType.PageView
                ? ['trackPageView', window.location.href]
                : [
                      'trackEvent',
                      {
                          id: eventType,
                          ...eventTypeProps,
                      },
                  ]
        )
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error tracking HubSpot event', error)
    }
}

const trackGtm = (eventType: EventType, eventTypeProps: object) => {
    try {
        gtm.track({ event: eventType, eventType, eventTypeProps })
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error tracking GTM event', error)
    }
}

const trackAmplitude = async (eventType: EventType, eventTypeProps: object): Promise<void> => {
    if (!process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY) {
        return
    }
    try {
        await amplitudeTrack(eventType, eventTypeProps).promise
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error tracking amplitude event', error)
    }
}

/*
 * Track event in Amplitude, HubSpot and GTM
 * This function won't throw an error if any of the tracking services fail
 */
export const track = async <T extends BaseTrackProps>(
    eventType: EventType,
    eventTypeProps: T
): Promise<void> => {
    trackHubSpot(eventType, eventTypeProps)
    trackGtm(eventType, eventTypeProps)
    await trackAmplitude(eventType, eventTypeProps)
}

export const identify = async (userId: string) => {
    amplitudeSetUserId(userId)
    //@ts-ignore
    var _hsq = (window._hsq = window._hsq || [])
    _hsq.push([
        'identify',
        {
            email: userId,
        },
    ])
}

export function hubspotOptIn(email: string, optIn: boolean) {
    //@ts-ignore
    var _hsq = (window._hsq = window._hsq || [])
    _hsq.push([
        'identify',
        {
            email: email,
            opt_in: optIn,
        },
    ])
    track(
        EventType.InputClick,
        GetInputEventProperties('opt-in', InputTrackClickType.ProfileOptInt)
    )
}

export const addUserProperties = async (extraData: { [name: string]: string }) => {
    const userIdentify = new AmplitudeIdentify()
    Object.keys(extraData).forEach((key) => {
        userIdentify.set(key, extraData[key])
    })
    amplitudeIdentify(userIdentify)
}

export enum EventType {
    ButtonClick = 'Button Click',
    CardClick = 'Card Click',
    InputBlur = 'Input Blur',
    InputClick = 'Input Click',
    ListingView = 'Listing View',
    LoginRequestToken = 'Login Token Request',
    LoginSuccess = 'Login Success',
    LoginMismatchToken = 'Login Token Mismatch',
    NewMapInteraction = 'New Map Interaction',
    OfferSentSuccess = 'Offer Sent Success',
    PageView = 'Page View',
    ScheduleSent = 'Schedule Sent',
    HomeSearch = 'Home Search',
    SearchAll = 'Search all',
    SearchRegion = 'Search by region',
    SearchStreet = 'Search by street',
    SingleFilterInteraction = 'Single Filter Interaction',
    ListingSearch = 'Listing Search',
}

export enum ButtonTrackClickType {
    AdvertiseProperty = 'divulgue_seu_imovel',
    ApplyFilters = 'aplicar_filtro',
    AfterScheduleMoreOptions = 'agendamento_mais_opcoes',
    BackToTop = 'voltar_topo',
    BannerRecommendedHome = 'recommended_home',
    Blog = 'blog',
    Breadcrumb = 'breadcrumb',
    CalcAdvertise = 'calculadora_anuncie',
    CalcContact = 'calculadora_contato',
    CalcNew = 'calculadora_novo',
    ClearFilters = 'limpar_filtro',
    CreateNewProfile = 'create_new_profile',
    CustomSearch = 'results_busca_personalizada',
    Filter = 'filtro',
    FilterClose = 'fechar_filtro',
    FilterCloseX = 'fechar_filtro_x',
    Footer = 'footer',
    Internal = 'botão_interno',
    ListingShare = 'compartilhar_imovel',
    Logo = 'logo',
    MapMarker = 'pin_mapa',
    ListingMapInteraction = 'interacao_mapa_listing',
    NotReceivedContactUs = 'nao_recebi_fale_conosco',
    Offer = 'enviar_proposta',
    OfferSent = 'offer_enviar',
    OfferGarantia = 'offer_garantia',
    OfferBusinessModal = 'offer_negócio_exemplo',
    OfferNegotiationModal = 'offer_negociaçao_exemplo',
    OfferBackButton = 'offers_voltar',
    OffersPageNextSteps = 'offers_page_proximos_passos',
    OffersPageDownloadDocsGuide = 'offers_page_download_guia_documentos',
    OffersPageBackButton = 'offers_page_voltar',
    OffersPageHelp = 'offers_page_ajuda',
    OffersPageOngoing = 'offers_page_em_andamento',
    OffersPageDenied = 'offers_page_não_aceitas',
    OffersPageSigned = 'offers_page_assinadas',
    Partners = 'para_parceiros',
    PriceSingleFilter = 'filtro_unico_preco',
    PrivacyPolicy = 'politica_de_privacidade',
    Schedule = 'agendamento',
    SearchAreaClick = 'busca_area_mapa',
    SearchProperty = 'buscar_imoveis',
    SearchText = 'busca_texto',
    SearchTextDelete = 'busca_texto_delete',
    SocialMedia = 'social_media',
    Sort = 'ordenar',
    SQMediaABF = 'sq_media_abf',
    SQMediaBlomberg = 'sq_media_blomberg',
    SQMediaExame = 'sq_media_exame',
    SQMediaPEGN = 'sq_media_pegn',
    SQMediaTerra = 'sq_media_terra',
    ToggleMapList = 'mobile_botao_mapa_lista',
    UserProfileBackButton = 'user_profile_back',
    UseTerms = 'termos_de_uso',
    FAQ = 'faq_ajuda',

    ModalCloseX = 'modal_fechar_x',
    ModalGalleryImage = 'modal_imagem_carrossel',

    ProfilingHome = 'profiling_home',
    ProfilingSearching = 'profiling_buscando',
    ProfilingBairro = 'profiling_bairro',
    ProfilingType = 'profiling_tipo_negocio',
    ProfilingTypeOther = 'profiling_tipo_negocio_outro',
    ProfilingOwner = 'profiling_proprietario',
    ProfilingFirst = 'profiling_primeiro_negocio',
    ProfilingHood = 'profiling_vizinhanca',
    ProfilingRevenue = 'profiling_aluguel',
    ProfilingName = 'profiling_nome',
    ProfilingPhone = 'profiling_telefone',
    ProfilingEmail = 'profiling_email',

    SpecialistCTA = 'especialista_cta',

    ScheduleHour = 'schedule_hora',
    ScheduleDate = 'schedule_data',
    ScheduleLeadSent = 'schedule_lead_enviado',

    //logged area
    CardAddFavorite = 'favorito_card_add',
    CardRemoveFavorite = 'favorito_card_remove',
    Favorites = 'favoritos',
    FavoriteFindProperty = 'favorito_encontrar_imovel',
    ListingAddFavorite = 'favorito_listing_add',
    ListingRemoveFavorite = 'favorito_listing_remove',
    Login = 'login',
    LoginMenu = 'login_menu',
    Logout = 'sair',
    Visits = 'visitas',
    Offers = 'propostas',
    VisitsFindProperty = 'visitas_encontrar_imovel',

    MenuVisitsItem = 'menu_visitas_area_iq',
    BackVisits = 'voltar_area_iq',

    VisitSendProposal = 'proposta_area_iq',
    VisitScheduleAgain = 'agendar_novamente_area_iq',
    VisitCancelModal = 'cancelar_visita_area_iq_modal',
    VisitCancel = 'cancelar_visita_area_iq',

    // Recomendados/Para-voce page
    ForYouSortLowestPrice = 'order_lower_price',
    ForYouSortHighestPrice = 'order_higher_price',
    ForYouSortMostSeen = 'order_most_viewed',
    ForYouSortMostRecent = 'order_newest',
    ForYouSortMoreTraffic = 'order_more_traffic',
    ForYouFilterSmallArea = 'filter_lower_size',
    ForYouFilterLargeArea = 'filter_upper_size',
    ForYouFilterMainType = 'filter_main_type',
    ForYouFilterNeighborhood1 = 'filter_neighborhood_one',
    ForYouFilterNeighborhood2 = 'filter_neighborhood_two',
    ForYouFilterNeighborhood3 = 'filter_neighborhood_three',
}

export enum InputTrackClickType {
    CalcAddress = 'calculadora_endereco',
    CalcAddressAutocomplete = 'calculadora_endereco_autocomplete',
    CalcName = 'calculadora_nome',
    CalcPhone = 'calculadora_telefone',
    CalcSize = 'calculadora_tamanho',
    CalcSupplyType = 'calculadora_tipo_imovel',

    ProfilingName = 'profiling_nome',
    ProfilingPhone = 'profiling_telefone',
    ProfilingEmail = 'profiling_email',

    ProfileName = 'profile_name',
    ProfilePhone = 'profile_phone',
    ProfileEmail = 'profile_email',
    ProfileOptInt = 'profile_opt_in',
    ProfileBusinessType = 'profile_business_type',
    ProfileBusinessTypeOther = 'profile_business_type_other',
    ProfileDealDeadline = 'profile_deal_deadline',
    Search = 'busca',
    Sort = 'ordenacao',

    OfferValor = 'offer_valor',
    OfferNegocio = 'offer_negocio',
    OfferCarencia = 'offer_carencia',
    OfferContractDuration = 'offer_prazo_de_contrato',
    OfferNegociacao = 'offer_negociacao',
}

export enum CardTrackClickType {
    PropertyCard = 'cartao_imovel',
    PropertyCardCarouselPaginate = 'cartao_imovel_carrossel_paginate',
    PropertyCardRecommended = 'cartao_imovel_recomendado',
    PropertyCardSectionPageview = 'cartao_imovel_trilhos_pageview',
    PropertyCardSectionNew = 'cartao_imovel_trilhos_mais_recentes',
    PropertyCardSectionBeforeRestaurant = 'cartao_imovel_trilhos_antes_era_restaurante',
    PropertyCardListing = 'cartao_imovel_listing',
    PropertyCardListingNearby = 'cartao_imovel_listing_proximo',
    PropertyCardScheduleSimilar = 'cartao_imovel_agendamento_similar',
    PropertyCardScheduleNearby = 'cartao_imovel_agendamento_similar_proximo',
    PropertyCardFavorites = 'cartao_imovel_favoritos',
    PropertyCardScheduledVisits = 'cartao_imovel_visitas_agendadas',
    PropertyCardExpandedSearch = 'cartao_imovel_busca_expandida',
    PropertyCardCalculator = 'cartao_imovel_calculadora',
    MapPropertyCard = 'cartao_imovel_mapa',
    SectionPropertyCard = 'cartao_imovel_trilhos',
    PropertyCardRecommendedPage = 'cartao_imovel_recomendados_page',
}

export enum FilterInteractionType {
    Price = 'preço',
}

export const GetButtonEventProperties = (
    displayName: string,
    buttonType: ButtonTrackClickType
): buttonTrackProps => {
    return {
        ...getBaseTracksValues(),
        displayName: displayName,
        buttonType: buttonType,
    }
}

export const GetCardEventProperties = (
    displayName: string,
    cardType: CardTrackClickType
): cardTrackProps => {
    return {
        ...getBaseTracksValues(),
        displayName: displayName,
        cardType: cardType,
    }
}

export const GetFilterInteractionProperties = (
    displayName: string,
    filterType: FilterInteractionType
): filterInterationTrackProps => {
    return {
        ...getBaseTracksValues(),
        displayName,
        filterType: filterType,
    }
}

export const GetInputEventProperties = (
    displayName: string,
    inputType: InputTrackClickType
): inputTrackProps => {
    return {
        ...getBaseTracksValues(),
        displayName: displayName,
        inputType: inputType,
    }
}

export const GetListingViewEventProperties = (supply: Supply) => {
    return {
        ...getBaseTracksValues(),
        ...getPrice(supply),
        supply: supply,
    }
}

export const GetLoginFluxEventProperties = (phone: string) => {
    return {
        ...getBaseTracksValues(),
        phone: phone,
    }
}

export const GetPageViewEventProperties = (
    suppliesQuantity?: number,
    supply?: Supply
): pageviewProps => {
    const formattedSupply = supply ? supplyToSupplyAmplitude(supply) : undefined
    return { ...getBaseTracksValues(), suppliesQuantity: suppliesQuantity, supply: formattedSupply }
}

const getPrice = (supply: Supply): { listing_rent_price: number; total_price: number } => {
    const price = supply.details['preço'] ? Number.parseInt(supply.details['preço'].value) : 0

    const iptu = supply.details['iptu'] ? Number.parseInt(supply.details['iptu'].value) : 0

    const condominio = supply.details['condominio']
        ? Number.parseInt(supply.details['condominio'].value)
        : 0
    return { listing_rent_price: price, total_price: price + iptu + condominio }
}

export const GetScheduleSentEventProperties = (
    supply: Supply,
    schedule: ScheduleSentProps,
    date_solicited: string
): SentTrackProps => {
    return {
        ...getBaseTracksValues(),
        ...getPrice(supply),
        ...schedule,
        date_solicited: date_solicited,
        listing_id: supply.id,
        listing_title: supply.title,
        timestamp: Date.now(),
        supply: supply,
    }
}

export const GetSearchEventProperties = (displayName: string): searchProps => {
    return {
        ...getBaseTracksValues(),
        displayName: displayName,
    }
}
