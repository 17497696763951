const gtm = {
    track: (props: object) => {
        if (typeof window === 'undefined') {
            return
        }
        /* @ts-ignore */
        window.dataLayer?.push({ ...props })
    },
}

export default gtm
