export const normalizePhone = (phone: string | undefined | null): string => {
    if (!phone) {
        return ''
    }

    if (typeof phone !== 'string') {
        return ''
    }

    const onlyDigits = phone.replace(/\D/g, '')
    const removeDDI = onlyDigits.startsWith('55') ? onlyDigits.slice(2) : onlyDigits
    const normalized = `55${removeDDI}`

    if (normalized.length !== 13 && normalized.length !== 12) {
        return ''
    }

    return normalized
}

export const phoneToNumbers = (phone: string) => {
    return phone.replace(/\D/g, '')
}

export const phoneReadableFormat = (phone: string) => {
    return phone.replace(/(\d{2})(\d{4,5})(\d{4})$/, '($1) $2-$3')
}
